.projects {
  margin: 3rem;
  padding: 3rem;
}

header {
  text-align: center;
  margin-bottom: 10rem;
  font-size: 3rem;
  font-weight: bold;
  color: var(--text);
}

.both {
  display: flex;
  height: 80vh;
  margin: 5rem 0;
}

.l-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  flex: 1;
}

.l-projects span {
  margin: 1.5rem;
}

.l-projects span:nth-child(1) {
  font-size: 2.5rem;
  color: var(--text);
}

.r-projects {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--darkblue);
  border-radius: 2rem;
  box-shadow: 5px 5px 5px 4px var(--darkblue);
  background: var(--darkblue);
  position: relative;
}

.p-icons {
  margin: 1.5rem;
  color: var(--text);
  transform: scale(130%);
}

@media screen and (max-width: 1098px) {
  .projects {
    margin: 1rem;
    padding: 0;
  }

  header {
    margin-bottom: 5rem;
  }

  .both {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 6rem;
    border-bottom: 4px solid var(--white);
    height: 55rem;
  }

  .both-s {
    display: flex;
    flex-direction: column;
    margin-bottom: 10rem;
  }

  .l-projects {
    margin: 0;
    padding: 0;
  }

  .r-projects {
    border: none;
    box-shadow: none;
  }

  @media screen and (max-width: 685px) {
    .big .r-projects img {
      transform: scale(55%);
    }

    .r-projects img {
      transform: scale(80%);
    }

    .r-projects img:nth-child(2) {
      left: 3rem;
    }
  }
}
