.contact {
  height: 60vh;
  display: flex;
  margin: 4rem;
  margin: 5rem;
}

.l-contact {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.l-contact span {
  margin: 1rem;
  font-size: 3rem;
  font-weight: bold;
}

.l-contact span:nth-child(2) {
  color: var(--text);
}

.r-contact {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 5rem;
}

.r-contact form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.r-contact .user {
  transform: scale(110%);
  width: 27rem;
  height: 2rem;
  padding: 5px;
  outline: none;
  border: 2px solid var(--text);
  border-radius: 8px;
  resize: none;
}

textarea {
  height: 6rem !important;
}

.submit-span {
  color: var(--text);
  font-size: 16px;
  font-weight: bold;
  display: none;
  margin: 1rem;
}

@media screen and (max-width: 768px) {
  .contact {
    height: 40rem;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
  }

  .l-contact span {
    margin: 0rem;
    font-size: 2.3rem;
  }

  .r-contact form {
    transform: scale(77%);
  }
}
