.footer {
  height: 18vh;
  background-color: #243747ca;
  border-radius: 6rem 6rem 0 0;
  box-shadow: 0px 15px 27px 5px var(--white);
}

.f-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.f-content span {
  color: var(--text);
  margin: 1.4rem;
}
.f-icons .f-icons {
  transform: scale(130%);
  color: var(--text);
  margin: 0.8rem;
  transition: transform 0.5s;
}

.f-icons .f-icons:hover {
  transform: scale(200%);
}

@media screen and (max-width: 768px) {
  .footer {
    height: 6.5rem;
  }

  .f-icons {
    transform: scale(70%);
  }

  .f-content span {
    margin: 0.8rem;
  }
}
