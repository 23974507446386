@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

:root {
    --white : #DFEAF1;
    --darkblue : #243747;
    --text: #FFF09C;
}

.App {
    overflow: hidden;
}

* {
    margin: 0%;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto Slab', serif;
    font-size: large;
}

.button{
    position: relative;
    border-radius: 36px;
    border: none;
    color: var(--text);
    font-size: 16px;
    padding: 7px 30px;
    overflow: hidden;
    background: linear-gradient(90deg, hsl(207, 33%, 21%) 0%, hsla(211, 36%, 46%, 1) 100%);
    box-shadow: 0px 20px 24px 3px var(--white);
    transition: transform 0.3s ease;
  }

  .button:hover{
    background: var(--white);
    cursor: pointer;
    color: var(--darkblue);
    border: var(--darkblue);
    transform: translateY(-5px);
  }

  .button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: var(--white);
    transition: height 0.3s ease, background-color 0.3s ease ; 
    z-index: -1;
    
}

.button:hover::after {
    height: 100%; 
    

}

