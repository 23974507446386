.about {
  display: flex;
  margin: 6rem;
  margin-top: 8rem;
}

.a-words {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem;
  margin: 0 auto;
  border: 1px solid var(--white);
  border-radius: 10px;
  box-shadow: 0px 10px 25px 5px var(--white);
  transition: box-shadow 0.5s;
  padding-bottom: 8rem;
}

.a-words:hover {
  box-shadow: 0px 10px 20px 4px var(--text);
}

span {
  font-size: 14px;
}

.text-container span {
  font-size: 3rem;
  font-weight: bold;
}

.me {
  color: var(--text);
}

@media screen and (max-width: 768px) {
  .about {
    margin: 0.8rem;
    margin-bottom: 13rem;
  }

  .a-words {
    gap: 0;
    padding: 0.5rem;
    padding-bottom: 4 rem;
    padding-bottom: 5rem;
  }

  .a-span {
    font-size: 14px;
    margin: 1rem;
  }

  .text-container {
    margin: 1.5rem;
  }

  .text-container span {
    font-size: 2.3rem;
  }
}
