.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.l-nav span {
  font-size: 1.5rem;
  color: var(--text);
}

.r-nav ul {
  display: flex;
  gap: 3.5rem;
}

.r-nav ul li {
  list-style-type: none;
  transition: transform 0.5s;
}

.r-nav ul li:hover {
  transform: scale(150%);
  cursor: pointer;
  color: var(--text);
}

.sidebar {
  display: none;
  object-fit: cover;
  height: 1.5rem;
}

.menu ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0rem;
  top: 4rem;
  z-index: 2;
  padding: 0.5rem;
  background: #243747a4;
  width: 14rem;
  border-radius: 1rem;
  list-style: none;
  position: fixed;
}

.menu ul li:active {
  background: var(--darkblue);
  color: var(--text);
}

.menu li {
  color: var(--darkblue);
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  margin-bottom: 0.8rem;
  background: #fff09ca4;
  border-radius: 0.5rem;
}

.n-button a {
  color: var(--text);
}

.n-button a:hover {
  background: var(--white);
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .nav ul,
  .n-button {
    display: none;
  }

  .sidebar {
    display: flex;
  }
}
