.skills {
  height: 77vh;
  display: flex;
  margin: 5rem;
}

.l-skills {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}

.l-skills > :nth-child(1) {
  font-size: 3rem;
  font-weight: bold;
  color: var(--text);
}

.l-skills > :nth-child(2) {
  font-size: 15px;
}

.list {
  margin-top: 3rem;
}

.list ul {
  display: flex;
  gap: 3rem;
  list-style-type: lower-greek;
  color: var(--text);
}

.r-skills {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.circle {
  left: 5rem;
  position: relative;
  width: 20rem;
  height: 20rem;
  border-radius: 100%;
  box-shadow: 0px 10px 25px 5px var(--white);
  top: 2rem;
  background-color: var(--darkblue);
}

.circle > * {
  position: absolute;
}

.circle > :nth-child(1) {
  top: 1.5rem;
  left: 8rem;
}

.circle > :nth-child(2) {
  left: 0rem;
  top: 9rem;
}

.circle > :nth-child(3) {
  left: 8rem;
  top: 9rem;
}

.circle > :nth-child(4) {
  left: 15.5rem;
  top: 9rem;
}

.circle > :nth-child(5) {
  left: 8rem;
  top: 17rem;
}

.s-circle {
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.c-image {
  transform: scale(100%);
}

.s-icons svg,
.c-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px;
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
}

@media screen and (max-width: 1236px) {
  .skills {
    height: 60rem;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    margin-bottom: 5rem;
  }

  .l-skills > :nth-child(1) {
    font-size: 2.3rem;
  }

  .l-skills > :nth-child(2) {
    font-size: 14px;
  }

  .list {
    transform: scale(80%);
    margin-left: -2rem;
    margin-bottom: 10rem;
  }

  .list ul {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    font-weight: 500;
    list-style-type: none;
  }

  .list ul li {
    border-bottom: 1px solid var(--text);
  }

  .circle {
    left: 0%;
    top: -7rem;
  }
}
