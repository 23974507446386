.intro {
  height: 77vh;
  display: flex;
  padding: 1rem;
  margin-bottom: 10rem;
}

.l-intro {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 4rem;
  flex: 1;
}

.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 5rem;
}

.i-name span:nth-child(1) {
  font-size: 2.8rem;
  font-weight: bold;
}

.i-name span:nth-child(2) {
  font-size: 2.8rem;
  color: var(--text);
  font-weight: bold;
  height: 4rem;
  white-space: nowrap;
  width: 20rem;
}

.i-name span:nth-child(3) {
  font-size: 15px;
}

.i-button {
  width: 8rem;
}

.r-intro {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-items: center;
  padding-top: 5rem;
}

.art-intro img {
  max-width: 100%;
  height: auto;
  width: 100%;
  transform: scale(100%);
}

.icons {
  margin-top: 1.5rem;
}

.a-icons {
  margin: 1rem;
  transform: scale(130%);
  color: var(--text);
  transition: transform 0.5s;
}

.a-icons:hover {
  transform: scale(200%);
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .intro {
    display: flex;
    flex-direction: column;
    height: 65rem;
    transform: scale(86%);
    padding: 0;
    margin-bottom: 5rem;
  }

  .l-intro {
    margin: 0;
    margin-top: 0;
  }

  .a-icons {
    margin: 0.8rem;
    transform: scale(115%);
  }

  .i-name span:nth-child(1) {
    font-size: 2.3rem;
  }

  .i-name span:nth-child(2) {
    font-size: 2.3rem;
  }

  .art-intro img {
    transform: scale(150%);
  }

  .i-button {
    width: 8rem;
  }
}
